<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <div class="pa-2"><center>Series Wise Repoert</center> </div><v-row justify="end" class="mr-5 my-5"><v-btn class="green" dark @click="exportF('table')">EXPORT</v-btn></v-row>
            <div style="display:none;" v-for="(item,index) in tabledata" :key='index'>
            <v-row class="my-2 grey white--text"><div class="pa-2">{{item.bc_name}}</div>  </v-row>
            <table id="exceltable">
               
                
               
                <tbody>
                <tr>
               <td> Series </td>
                <td> Total </td>
                <td> Weed Out </td>
                <td> Handed over </td>
                <td> issue </td>
                <td> Available </td></tr>
                <tr v-for="(item1,index1) in series_list" :key='index1'>
                
                        <td>
                        {{item1.sname}}
                        </td>
                         <td>
                        {{item1.total_book}}
                        </td>
                         <td>
                        {{item1.total_Weed_out}}
                        </td>
                         <td>
                        {{item1.total_ho}}
                        </td>
                         <td>
                        {{item1.total_issue}}
                        </td>
                         <td>
                        {{item1.total_available}}
                        </td>
                
                </tr>
              
                </tbody>
            </table>
            </div>
             <v-skeleton-loader
      v-if="loading"
      v-bind="attrs"
      type="divider, list-item-three-line, card-heading"
    ></v-skeleton-loader>
       <div v-else v-for="(item,index) in tabledata" :key='index'>
            <v-row class="my-2 grey white--text"><div class="pa-2">{{item.bc_name}}</div>  </v-row>
            <v-simple-table id="exceltable">
                <thead>
                <th> Series </th>
                <th> Total </th>
                <th> Weed Out </th>
                <th> Handed over </th>
                <th> issue </th>
                <th> Available </th>
                </thead>
                <tbody>
               
                <tr v-for="(item1,index1) in item.series_list" :key='index1'>
                        <td>
                        {{item1.sname}}
                        </td>
                         <td>
                        {{item1.total_book}}
                        </td>
                         <td>
                        {{item1.total_Weed_out}}
                        </td>
                         <td>
                        {{item1.total_ho}}
                        </td>
                         <td>
                        {{item1.total_issue}}
                        </td>
                         <td>
                        {{item1.total_available}}
                        </td>

                </tr>
              
                </tbody>
            </v-simple-table>
            </div>
    </div>
</template>

<script>
    import axios from "axios";
   import {table2excel} from "../jquery.table2excel";
    export default {
        data: () => ({
            tabledata:"",
            series_list:[],
            loading:false,
        }),
        mounted() {
                this.init();
        },
        methods: {
             exportF(elem) {
                 
   $("#exceltable").table2excel({  
          name: "Worksheet Name",
          filename: "Serieswisereport", //do not include extension
          fileext: ".xls" // file extension
        });        
      },

            init()
            {
                this.loading=true;
                axios
                    .post("/Librarian/get_series_wise_report")
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.loading=false;
                            this.tabledata=res.data.data;
for(var i in this.tabledata)
{    this.tabledata[i].series_list.splice(0,0,{"total_available":"","total_issue":"","total_ho":"","total_Weed_out":"","total_book":"","sname":this.tabledata[i].bc_name});

     
    for(var j in this.tabledata[i].series_list)
{
   this.series_list.push( this.tabledata[i].series_list[j]);
}

}
                    console.log(this.tabledata);
                            }
                        })
                    }
               
            
        },
    };
</script>
<style scoped>
    .v-text-field {
        padding-top: 0px;
        margin-top: 0px;
    }
    .v-icon-size {
        font-size: 20px;
    }
    .edit-avatar {
        transition: 0.3s;
        border: 1px solid #b0bec5;
    }
    .edit-avatar:hover {
        background-color: #b0bec5;
    }
    .edit-v-icon {
        padding-left: 6px;
        font-size: 15px;
    }
    .edit-v-icon:hover {
        color: white;
    }
    .fields {
        padding: 0px !important;
    }
    .cus-card {
        background: #d3d3d33d;
        margin: 0rem 0.5rem 0.5rem 0.5rem;
        padding: 1rem;
        border: 2px solid gray;
    }
    .c-label {
        font-weight: bold;
    }
    .elevation-1 /deep/ tr {
        white-space: nowrap !important;
    }
    .checkbox /deep/ .v-input--selection-controls{
        margin-top: 0px  !important;
    }
    .d-btn {
        display: flex;
        justify-content: flex-end;
        margin: 0.5rem;
    }
    .checkbox{
        margin: 1rem;
    border: 1px solid;
    background: #d3d3d380;
    }
    .checkbox .box{
        margin-top: 5px;
    }
</style>
